export default function BigNumberFilter(reviews: number) {
    if (reviews > 1000000) {
        return `${parseFloat((reviews / 1000000).toFixed(1))}M`;
    }

    if (reviews > 1000) {
        return `${parseFloat((reviews / 1000).toFixed(1))}k`;
    }

    return String(reviews);
}
