
















import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScoreRating from '@/modules/common/components/ui-kit/score-rating.vue';
import Range from '@/modules/common/components/ui-kit/range.vue';
import RankingBreakdown from '@/modules/ranking/components/ranking-breakdown.vue';
import RankingService, { RankingServiceS } from '@/modules/ranking/ranking.service';
import RankingProvider from './ranking-provider.vue';

@Component({
    components: {
        RankingProvider,
        RankingBreakdown,
        Range,
        ScoreRating,
    },
})
export default class RankingProviders extends Vue {
    @Inject(RankingServiceS) private rankingService!: RankingService;

    get providers() {
        return this.rankingService.providers;
    }
}
