













import { Component, Vue } from 'vue-property-decorator';
import CompsetDocumentFilter from '@/modules/document-filters/components/compset-type-document-filter.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import ExcelBtn from '@/modules/common/components/excel-btn.vue';

@Component({
    components: {
        CustomSelect,
        CompsetDocumentFilter,
        ExcelBtn,
    },
})
export default class RankingActions extends Vue {
}
