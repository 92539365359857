






















import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import FEATURES from '@/modules/common/constants/features.constant';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { Location } from 'vue-router';

@Component
export default class RankingHeader extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;

    get urlChainClusterParam() {
        if (this.userService.isClusterUser) {
            return 'cluster';
        }

        return 'chain';
    }

    get hotelName() {
        const { currentHotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }
        return this.hotelsService.getHotelName(currentHotelId);
    }

    get isUnderChainOrCluster() {
        return !this.$route.path.startsWith('/hotel');
    }

    get feature() {
        return FEATURES.GUEST_REVIEW;
    }

    get backRoute(): Location {
        const branch = this.$route.name!.split('.')[0];

        switch (branch) {
            case 'chain':
                return {
                    name: 'chain.ranking',
                    params: this.$route.params,
                };

            case 'cluster':
                return {
                    name: 'cluster.ranking',
                };

            default: return {};
        }
    }
}
